<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <!-- <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
      
      </div> -->
      <!-- <yushe /> -->

      <div class="player listmain type-ssc xjsssc game-xjsssc game-ssc-qt">
        <!--  begin-->
        <div :class="'data '+classArr[index] " v-for="(item,index) in rowsData" :key="item.title">
          <h3>{{item.title}}</h3>
          <ul>
            <li v-for="ele in item.arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
              <ol class="td_name">
                {{ele.label}}
              </ol>
              <ol class="td_rate">
                {{ele.Odds}}
              </ol>
              <ol class="td_cash">
                <input v-model="ele.money" @click.stop="showOrHideCheck($event, ele)" type="number" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click.stop="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

          </ul>
        </div>
        <!-- 前三 end -->

      </div>

      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  data() {
    return {
      groupnames:'qt',
      classArr: ["cZ1-Z2-Z3", "cZ2-Z3-Z4", "cZ3-Z4-Z5"],
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "前三", arr: [] },
        { title: "中三", arr: [] },
        { title: "后三", arr: [] },
      ],
      label: ["豹子", "顺子", "对子", "半顺", "杂六"],
    };
  },

  mixins: [mixins],
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: item.title + ele.label,
                  Odds: ele.Odds,
                  title: "其他",
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });
          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 前三
        let arr1 = this.filterData(resAArr, 73, 77, this.label);
        this.rowsData[0].arr = [...arr1];
        //  中三
        let arr2 = this.filterData(resAArr, 78, 82, this.label);
        this.rowsData[1].arr = [...arr2];
        // 后三
        let arr3 = this.filterData(resAArr, 83, 87, this.label);
        this.rowsData[2].arr = [...arr3];

        // this.$forceUpdate()
      },
    },
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  created() {},
  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain.game-ssc-kd .data,
.listmain.game-ssc-lh .data,
.listmain.game-ssc-qt .data {
  margin-right: 2px;
  width: calc((100% - 6px) / 3);
}
</style>
